$color-primary: #B11737;
$color-secondary: #4CAF50;

$xs-min: 'min-width: 500px';
$sm-min: 'min-width: 768px';
$md-min: 'min-width: 992px';
$lg-min: 'min-width: 1200px';

$xs-max: 'max-width: 499px';
$sm-max: 'max-width: 767px';
$md-max: 'max-width: 991px';
$lg-max: 'max-width: 1199px';

@use '@angular/material' as mat;
@include mat.core();

$elmo-red: (
    50: #fffbfc, 
    100: #fbdde3, 
    200: #ef8399, 
    300: #e95674, 
    400: #de1d45, 
    500: #b11737, 
    600: #b11737, 
    700: #b11737, 
    800: #b11737, 
    900: #b11737, 
    A100: #b11737, 
    A200: #b11737, 
    A400: #b11737, 
    A700: #b11737, 
    contrast: ( 
        50: rgba(black, 0.87), 
        100: rgba(black, 0.87), 
        200: rgba(black, 0.87), 
        300: rgba(black, 0.87), 
        400: white, 
        500: white, 
        600: white, 
        700: white, 
        800: rgba(black, 0.87), 
        900: rgba(black, 0.87), 
        A100: rgba(black, 0.87), 
        A200: white, 
        A400: white, 
        A700: white)
);

$elmo-primary: mat.define-palette($elmo-red);
$elmo-accent: mat.define-palette(mat.$green-palette);

// The warn palette is optional (defaults to red).
$elmo-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$elmo-theme: mat.define-light-theme((color: (primary: $elmo-primary,
                accent: $elmo-accent,
                warn: $elmo-warn,
            ),
            typography: mat.define-typography-config(),
            density: 0,
        ));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($elmo-theme);

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre,a, abbr, address, cite, code, del, dfn, em, img, ins, q, small, strong,
sub, sup, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, a, img, menu {
    border: 0;
    margin: 0;
    padding: 0;
}

h1, h2, h3 {
    padding: 0.5em;
}

p {
    padding: 1em;
}

// ----- RAAMWERK ----- //
html, body {
    width: 100%;
    height: 100%;
}

body {
    font-family: "Helvetica Neue",sans-serif;
}

app-root {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .content {
        padding: 16px;
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    ng-component {
        height: 100%;
        max-width: 1200px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }
}

.title-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.container {
    padding: 16px;

    .flex-auto {
        flex: auto;
    }

    .flex-column {
        display: flex;
        flex-direction: column;
    }

    .flex-row {
        display: flex;
        flex-direction: row;
    }
}

.mat-divider {
    margin: 8px 0 !important;
}

.hide-sm {
    @media(max-width: #{$sm-max}) {
        display: none !important;
    }
}

.show-sm {
    @media(min-width: #{$sm-min}) {
        display: none !important;
    }
}

// Toolbar
.toolbar-spacer {
    flex: 1 1 auto;
}

.toolbar-nav-buttons {
    margin-left: 8px;
}

// App Loader
app-loader {
    div.loader-overlay {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10000;
        background: rgba(255, 255, 255, 0.55);
        transition: 2s linear;

        mat-spinner {
            position: absolute;
            left: calc(50% - 50px);
            top: calc(50% - 50px);
        }
    }
}

// Snackbar
snack-bar-container {
    &.success {
        background-color: $color-secondary;
        color: #fefefe;
        text-shadow: 1px 1px rgba(255,255,255,.1);
    }

    &.error {
        background-color: $color-primary;
        color: #fefefe;
        text-shadow: 1px 1px rgba(255,255,255,.1);
    }
}

// Table
.clickable-row {
    cursor: pointer;
}

.column-width-48, .mat-column-OrganisationID, .mat-column-ApplicationID {
    max-width: 48px;
}
.column-width-128, .mat-column-Code {
    max-width: 128px;
}
.column-width-280, .mat-column-Guid, .mat-column-Name {
    max-width: 280px;
}

// Buttons
.btn-margin-left {
    margin-left: 16px !important;
}

// Landingspagina
.landingspagina {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}
